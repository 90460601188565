<template>
  <div class="w-full bg-bluegray-50">
    <div class="container flex flex-column flex-lg-row justify-content-between align-items-center my-3">
      <div class="w-full text-red-500	text-center text-lg-start">
        ราคาสินค้าอาจจะมีการเปลี่ยนแปลงได้ <b>"ราคารายวัน"</b>
      </div>
      <PVDivider class="block lg:hidden" />
      <div class="w-full text-center text-lg-end">
        กล่องสุ่มราคาส่งจากโรงงานโดยตรง <b>"ไม่ผ่านคนกลาง"</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>
