<template>
  <div id="app">
    <VueElementLoading
      :active="isActiveFullLoading"
      background-color="rgba(255, 255, 255, 1)"
      duration="1.0"
      size="60"
      color="#0387ff"
      is-full-screen
    />
    <PVToast position="top-center" class="z-index-5000" />
    <div class="min-h-screen flex flex-column">
      <HeadPage />
      <div class="container flex-1 py-3">
        <router-view/>
      </div>
      <FooterPage />
    </div>
  </div>
</template>

<script>
// innerHeight ========================================================
var vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});
// ====================================================================

import HeadPage from "@/components/HeadPage";
import FooterPage from "@/components/FooterPage";
import { useToast } from 'primevue/usetoast';

export default {
  name: "App",
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      isActiveFullLoading: true,
    };
  },
  components: {
    HeadPage,
    FooterPage,
  },
};
</script>
<style>
@import url("~@/assets/css/style.css");
</style>
