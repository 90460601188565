import { createWebHistory, createRouter } from "vue-router";

const title = "- Kodung Soom !";

const routes = [
  {
    path: "/",
    // redirect: "/home",
    name: "HomePage",
    component: () => import("@/components/HomeComponent.vue"),
    meta: {
      pageName: "home",
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: "404",
    component: () => import("@/components/PageNotFound.vue"),
    meta: {
      title: "404 Page not found - " + title,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  next();
});

export default router;
