<template>
  <div class="w-full bg-bluegray-50">
    <div class="container flex justify-content-between align-items-center my-3">
      <div class="flex align-items-center">
        <router-link to="/">
          <PVButton link class="p-0 m-0">
            <div class="img-logo border-circle mr-2">
              <img
                class="object-fit-cover h-100 w-100"
                src="~@/assets/logo.jpg"
              />
            </div>
            <span class="hidden md:block text-900 hover:text-600 text-lg font-semibold">
              Kodung Soom !
            </span>
          </PVButton>
        </router-link>
      </div>
      <div class="flex align-items-center">
        <PVButton
          type="button"
          rounded
          outlined
          severity="warn" 
          label="สอบถามสั่งซื้อสินค้า"
          @click="contactHead"
        />
        <PVPopover ref="contactHeadPopover" class="contactHeadPopover">
          <div class="flex flex-col gap-4 w-[25rem]">
            <!-- Line Official Account -->
            <div class="text-center">
              <span class="font-medium font-semibold">Line Official Account</span>
              <PVDivider />
              <div class="flex flex-column align-items-center mx-2 pt-4 md:pt-0">
                <PVInputGroup>
                  <PVInputText
                    :value="linkLineOA"
                    readonly
                    class="w-[25rem]"
                  ></PVInputText>
                  <PVButton class="text-decoration-none border-round-right-sm" as="a" :href="linkLineOA" target="_blank" severity="warn" icon="pi pi-globe" />
                </PVInputGroup>
                <div class="img-qrHead mt-2">
                  <img
                    class="object-fit-cover h-100 w-100"
                    src="~@/assets/lineOA.png"
                  />
                </div>
              </div>
            </div>
            <!-- ------------ -->
          </div>
        </PVPopover>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "HeadPage",
  data() {
    return {
      linkLineOA: "https://lin.ee/L61Byy1",
    };
  },
  setup() {
    const contactHeadPopover = ref();
    return {
      contactHeadPopover,
    };
  },
  methods: {
    contactHead() {
      this.contactHeadPopover.toggle(event);
    },
  },
};
</script>
