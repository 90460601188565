var numeral = require("numeral");

export default {
  currencyDigi0(value) {
    if (value == null) {
      return "";
    } else {
      return numeral(value || 0).format("0,0");
    }
  },
};
