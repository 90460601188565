// primevue css ==============================
import 'primeicons/primeicons.css'
import "primeflex/primeflex.css";
import "primeflex/themes/primeone-light.css";
// import "primeflex/themes/primeone-dark.css";
// ===========================================
import "bootstrap/dist/css/bootstrap.min.css";
// ===========================================

import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import axios from "axios";
import VueElementLoading from 'vue-element-loading';

// mixin =====================================
import currency from './mixin/currency';

// primevue ==================================
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import PVButton from "primevue/button"
import PVPopover from 'primevue/popover';
import PVInputGroup from 'primevue/inputgroup';
import PVInputGroupAddon from 'primevue/inputgroupaddon';
import PVInputText from 'primevue/inputtext';
import PVDivider from 'primevue/divider';
import PVDataView from 'primevue/dataview';
import PVImage from 'primevue/image';
import PVSelectButton from 'primevue/selectbutton';
import PVTag from 'primevue/tag';
import PVToast from 'primevue/toast';
import PVToastService from 'primevue/toastservice';
// ===========================================

export const app = createApp(App)
// use =======================================
app.use(router)
app.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            // prefix: 'p',
            // darkModeSelector: 'system',
            darkModeSelector: 'false',
            // cssLayer: false
        }
    }
});
app.use(PVToastService);
// ===========================================

app.component('VueElementLoading', VueElementLoading)
// primevue ==================================
app.component("PVButton", PVButton);
app.component("PVPopover", PVPopover);
app.component("PVInputGroup", PVInputGroup);
app.component("PVInputGroupAddon", PVInputGroupAddon);
app.component("PVInputText", PVInputText);
app.component("PVDivider", PVDivider);
app.component("PVDataView", PVDataView);
app.component("PVImage", PVImage);
app.component("PVSelectButton", PVSelectButton);
app.component("PVTag", PVTag);
app.component("PVToast", PVToast);
// ===========================================

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$filters = {
    ...currency,
};
app.mount("#app");
